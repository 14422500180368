.App {
  text-align: center;
  background-color: #b4a6ca;
  height: 100vh;
}

.App-header {
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #210b32;
  width: 100%;
  overflow: hidden;
}

.App-link {
  color: #61dafb;
}

.App .App-header img {
  display: block;
  height:  80vh;
  width:  auto;
}



